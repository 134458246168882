import "./books.css";
import React from "react";
import englishBook from "../../../assets/english.png";

export default function Books() {
  return (
    <div className="container" id="books">
      <h3>The Muallimah’s Books</h3>
      <div className="booksContainer">
        <div className="book">
          <img className="image" src={englishBook} />
          <h4>Hello, little academic</h4>
          <p>35.000 UZS</p>
        </div>

        <div className="book">
          <img className="image" src={englishBook} />
          <h4>28 kunda mukammal Ingliz tili</h4>
          <p>25.000 UZS</p>
        </div>

        <div className="book">
          <img className="image" src={englishBook} />
          <h4>MY GRAMMAR NOTES</h4>
          <p>65.000 UZS</p>
        </div>
      </div>
    </div>
  );
}
