import "./shop.css";
import React from "react";
import product1 from "../../../assets/product1.png";
import product2 from "../../../assets/product2.png";
import product3 from "../../../assets/product3.png";

export default function Shop() {
  return (
    <div className="container" id="shop">
      <h3>Shop</h3>
      <div className="shopContainer">
        <div className="shop">
          <img className="image" src={product1} />
          <h4>Classic Long Sleeve Tee</h4>
          <p>150.000 UZS</p>
        </div>

        <div className="shop">
          <img className="image" src={product2} />
          <h4>Classic Pullover Hoodie</h4>
          <p>250.000 UZS</p>
        </div>

        <div className="shop">
          <img className="image" src={product3} />
          <h4>All-Over Print Joggers</h4>
          <p>99.000 UZS</p>
        </div>
      </div>
    </div>
  );
}
