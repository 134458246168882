import "swiper/css";
import "./blog.css";
import { View } from "../../../assets";
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

const blogs = [
  {
    img: "https://www.onlineclassassist.com/wp-content/uploads/2019/01/1237-Blog-Image-W1.jpg",
    title: "Ways to learn foreign languages faster",
    desciption:
      "There are  different ways of learning foreign languages. Now we will look through five of them.",
    date: "February 18, 2021",
    viewCount: "749",
  },
  {
    img: "https://ktwop.files.wordpress.com/2017/07/words.jpg",
    title: "How to learn 50+ words in a day",
    desciption:
      "Learning languages is really easy and at the same time it is one most enjoyable activities. However, sometimes we struggle learn...",
    date: "February 18, 2021",
    viewCount: "749",
  },
  {
    img: "https://tubefluence.com/wp-content/uploads/2021/08/how-to-post-youtube-videos-on-forums-1024x576.png",
    title: "A guide post on how to use my YouTube videos",
    desciption:
      "Since I have opened my YouTube channel many of  you ask how to use my videos or where you guys should start. Please study this guide and never get confused with...",
    date: "February 18, 2021",
    viewCount: "749",
  },
  {
    img: "https://jhfr10winstonlee.files.wordpress.com/2014/02/languages.jpg",
    title: "How many languages do I  know?",
    desciption:
      "How many languages do I know and how did I learn them, these are the two questions I am asked most.Today I’m going to share my experience and some...",
    date: "February 18, 2021",
    viewCount: "749",
  },
];

const filters = [
  { name: "All posts", key: 0 },
  { name: "Languages", key: 1 },
  { name: "Religion", key: 2 },
  { name: "Modest fashion", key: 3 },
  { name: "Travelling", key: 4 },
  { name: "Cooking", key: 5 },
  { name: "The Muallimah's Playlists", key: 6 },
];

export default function Blog() {
  const [activeBlog, setActiveBlog] = useState(0);
  return (
    <div className="blogContainer container" id="blog">
      <h3>Blog</h3>
      <Swiper
        spaceBetween={20}
        slidesPerView={6}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {filters.map((f) => {
          return (
            <SwiperSlide key={f.key}>
              <div
                onClick={() => setActiveBlog(f.key)}
                className={`blogFilter ${activeBlog == f.key && "activeBlog"}`}
              >
                {f.name}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="blogInnerContainer">
        <Swiper
          spaceBetween={20}
          slidesPerView={3}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {blogs.map((b, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="blog">
                  <img className="blogImg" src={b.img} />
                  <div className="blogInnerContent">
                    <h4>{b.title}</h4>
                    <p>{b.desciption}</p>
                  </div>
                  <div className="blogFooter">
                    <p>{b.date}</p>
                    <div className="viewCount">
                      <h6>{b.viewCount}</h6>
                      <View />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}
