import "swiper/css";
import "./courses.css";
import React, { useRef, useState } from "react";
import Course1 from "../../../assets/course1.svg";
import Course2 from "../../../assets/course2.svg";
import Course3 from "../../../assets/course3.svg";
import Course4 from "../../../assets/course4.svg";
import ArrowRight from "../../../assets/arrowRight.svg";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Rating, CourseBack, CourseNext } from "../../../assets/index.js";

const courses = [
  {
    img: Course1,
    title: "Fluent English in 3 steps",
    desciption:
      "Ingliz tilini noldan o’rganishni xohlovchilar uchun The Muallimah 6 oylik maxsus kurslari Ushbu kursda siz ingliz tilini o’qish qoidalaridan boshlab...",
    lessonsCount: "100",
  },
  {
    img: Course2,
    title: "American English just in 8 hours",
    desciption:
      "Ushbu “AMERICAN ENGLISH JUST IN 8 HOURS” 8 soatda Amerika ingliz tilisi kursimiz Amerika qo’shma shtatlaridan...",
    lessonsCount: "80",
  },
  {
    img: Course3,
    title: "My Grammar Notes",
    desciption:
      "Ingliz tilidagi barcha grammatik mavzularni o’z ichiga olgan “My Grammar Notes” kursimiz ingliz tilini anchadan beri o’rganayotgan lekin hali ham grammatika mavzularida qiynalayotgan yoki ingliz...",
    lessonsCount: "35",
  },
  {
    img: Course4,
    title: "English Tenses Review",
    desciption:
      "Ushbu kursimiz orqali ingliz tilidagi barcha zamon strukturalarini o’rganishingiz mumkin. Kursimiz qisqa bo’lishiga qaramasdan siz unda zamonlarga tegishli barcha ma’lumotlarni olishingiz...",
    lessonsCount: "10",
  },
];

export default function Courses() {
  const swiper = useSwiper();
  return (
    <div className="coursesContainer container" id="courses">
      <h3>Courses</h3>
      <div className="coursesInnerContainer">
        <Swiper
          spaceBetween={20}
          slidesPerView={3}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {courses.map((c, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="course">
                  <img className="courseImg" src={c.img} />
                  <div className="courseRating">
                    <h5>4.5</h5>
                    <Rating />
                    <Rating />
                    <Rating />
                    <Rating />
                    <Rating />
                  </div>
                  <div className="blogContent">
                    <h4>{c.title}</h4>
                    <p>{c.desciption}</p>
                  </div>
                  <div className="courseFooter">
                    <p>{`${c.lessonsCount} lessons in total`}</p>
                    <img onClick={() => {}} src={ArrowRight} />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}
