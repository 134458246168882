import "./aboutUs.css";
import React from "react";
import Teacher from "../../../assets/Teacher.png";

export default function AboutUs() {
  return (
    <div className="container" id="about">
      <div className="aboutUsContainer">
        <h3>About The Muallimah</h3>
        <div className="row d-flex align-items-center">
          <div className="col-lg-4 col-md-5">
            <img src={Teacher} alt="" />
          </div>
          <div className="col-lg-5 col-md-5">
            <p>
              Find out more about the most popular and experienced uzbek ESL
              tutor/teacher of EuroAsia, who has more than 15 years of teaching
              experience in Uzbekistan and in EuroAsia.{" "}
            </p>
            <button>Learn more</button>
          </div>
        </div>
      </div>
    </div>
  );
}
