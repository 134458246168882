import "./slider.css";
import React from "react";
import englishBook from "../../../assets/english.png";

const delay = 3500;
export default function Slider({ colors }) {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => resetTimeout();
  }, [index]);

  return (
    <div className="slideshow">
      <div className="container">
        <h3>Free Online Language Courses</h3>
        <div
          className="slideshowSlider"
          // style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
        >
          {colors.map((backgroundColor, i) => (
            <div
              key={i}
              className="slide"
              style={{ opacity: index === i ? 1 : 0 }}
            >
              <div className="row">
                <div className="col-lg-8 col-md-5">
                  <div className="sliderLeft">
                    <h4>250+ Free online courses</h4>
                    <p>
                      This course is for self-disciplined, hard-working and
                      ambitious
                      <br />
                      students. Learn English with the Muallimah any time,
                      anywhere
                      <br />
                      and become fluent in English.
                    </p>
                    <button>Learn free</button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-5">
                  <div className="sliderRight">
                    <img src={englishBook} alt="logo" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="dotsContainer">
          <div className="slideshowDots">
            {colors.map((_, i) => (
              <div
                key={i}
                className={`slideshowDot${index === i ? " active" : ""}`}
                onClick={() => {
                  setIndex(i);
                }}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
