import React from "react";
import Shop from "./Shop";
import Blog from "./Blog";
import Books from "./Books";
import Slider from "./Slider";
import Footer from "./Footer";
import Counter from "./Counter";
import Courses from "./Courses";
import AboutUs from "./AboutUs";
import Feedbacks from "./Feedbacks";
import Navbar from "../../Components/Navbar";
const colors = ["#0088FE", "#00C49F", "#FFBB28"];

export default function Landing() {
  return (
    <div>
      <Navbar />
      <Slider colors={colors} />
      <Counter />
      <Courses />
      <AboutUs />
      <Blog />
      <Books />
      <Shop />
      <Feedbacks />
      <Footer />
    </div>
  );
}
