import "./footer.css";
import React from "react";
import {
  LogoWhite,
  Telegram,
  Instagram,
  Facebook,
  TikTok,
} from "../../../assets";

export default function Footer() {
  return (
    <div className="footerContent">
      <div className="container">
        <div className="row">
          <div className="col-4">
            <LogoWhite />
          </div>
          <div className="col-8 row align-items-center">
            <div className="col-8">
              <nav class="navbar navbar-expand-lg">
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavAltMarkup"
                  aria-controls="navbarNavAltMarkup"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                  <div class="navbar-nav footerLinks">
                    <a class="nav-item nav-link active" href="#">
                      Home
                    </a>
                    <a class="nav-item nav-link" href="#blog">
                      Blog
                    </a>
                    <a class="nav-item nav-link" href="#about">
                      About
                    </a>
                    <a class="nav-item nav-link" href="#courses">
                      Courses
                    </a>
                    <a class="nav-item nav-link" href="#books">
                      Books
                    </a>
                    <a class="nav-item nav-link" href="#shop">
                      Shop
                    </a>
                  </div>
                </div>
              </nav>
            </div>
            <div className="col-4">
              <p className="followUs">Follow us</p>
              <div className="socials">
                <div className="social">
                  <Telegram />
                </div>
                <div className="social">
                  <Instagram />
                </div>
                <div className="social">
                  <Facebook />
                </div>
                <div className="social">
                  <TikTok />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom row">
          <div className="col-6 align-self-end">
            <p>Copyright © 2021 - 2021 The Muallimah. All rights reserved.</p>
          </div>
          <div className="col-2">
            <p>Privacy Policy</p>
          </div>
          <div className="col-4">
            <p>Accessibility</p>
          </div>
        </div>
      </div>
    </div>
  );
}
