import "./navbar.css";
import React from "react";
import { Logo } from "../../assets";

export default function Navbar() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-4">
          <Logo />
        </div>
        <div className="col-8">routes</div>
      </div>
    </div>
  );
}
