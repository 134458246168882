import "swiper/css";
import "./feedbacks.css";
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

const feedbacks = [
  {
    id: 1,
    name: "Axmadjon",
    surname: "Axmadjonov",
    img: "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
    title:
      "Oldingi My grammar notes#1 da o'qiganimda darslarda Uyga vazifa ko'p berilardi va bularni bajarish uchun vaqt kam berilardi. Asosan biz o'zimiz qattiq harakat qilishimiz kerak bo'lardi. Darslar tez o'tilardi. Speaking ga unchalik ko'p etibor bermasdik. Ko'proq urg'u grammar ga qaratilardi.Hozirgi Men o'qiyotgan My grammar notes#3 oldingisidan tubdan farq qiladi. Darslar sifatli va tushunarli. Londa va o'rtacha tezlikda o'tiladi. Vazifalar ham ko'p emas va ularni bajarish uchun vaqt yetarli. Hozirgi kursimizda faqat grammarga etibor qaratmasdan speaking ga juda ham katta urg'u berilmoqda. Writing reading ayniqsa pronunciation ga ham e'tibor kuchli. Xulosa qilib aytganda hozirgi My grammar notes#3 kursimiz asosan grammar ga tashkil qilingan bo'lsada unda talabalar grammar bilimlarini mustahkamlab balki speaking writing ko'nikmalarini ham oshirishlari mumkin.",
  },
  {
    id: 2,
    name: "Muhammad",
    surname: "Karimov",
    img: "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
    title:
      "Thanks to your site, I have repeated many topics and learned a lot of new things. I especially like the Teacher method. I would like to go through it until the very last lesson.",
  },
  {
    id: 3,
    name: "Hasan",
    surname: "Musayev",
    img: "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
    title:
      "I like the thoughtfulness of the presentation of topics. Colorful and creative! The site is easy to use. I want to win a course for a year to study the language further.",
  },
  {
    id: 4,
    name: "Ilhomjon",
    surname: "Musayev",
    img: "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
    title:
      "I learn a language through the application. Simplicity, clarity, consistency - these are the main and main qualities of the learning system that The Muallimah relies on. I want to read the books of the great English classics in the original. I love England and everything English.",
  },
  {
    id: 5,
    name: "Nilufar",
    surname: "Usmonova",
    img: "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
    title:
      "I love games 😝 although other formats of learning in The Muallimah suit me perfectly!",
  },
  {
    id: 6,
    name: "Husan",
    surname: "Musayev",
    img: "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
    title:
      "I am a teacher and I recommend your site for learning English to my students. A large number of useful exercises that have no analogues on the Internet, the material is always very interesting and effective. Excellent resource",
  },
];
export default function Feedbacks() {
  return (
    <div className="feedbackContainer">
      <div className="container">
        <h3>Feedbacks</h3>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {feedbacks.map((f) => {
            return (
              <SwiperSlide key={f.id}>
                <div className="feedback">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-4 d-flex justify-content-center">
                      <img className="feedbackImg" src={f.img} />
                    </div>
                    <div className="col-8">
                      <div className="feedbackContent">
                        <h4>{`${f.name} ${f.surname}`}</h4>
                        <p>{f.title}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}
