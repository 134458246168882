import "./counter.css";
import React from "react";
import Counter1Svg from "../../../assets/counter1.svg";
import Counter2Svg from "../../../assets/counter2.svg";
import Counter3Svg from "../../../assets/counter3.svg";
import Counter4Svg from "../../../assets/counter4.svg";
import Counter5Svg from "../../../assets/counter5.svg";

export default function Counter() {
  return (
    <div className="container">
      <div className="counterContainer">
        <div className="counter">
          <h4 className="first">15+</h4>
          <img src={Counter1Svg} />
          <p>Years of experience</p>
        </div>
        <div className="counter">
          <h4 className="second">300+</h4>
          <img src={Counter2Svg} />
          <p>Students</p>
        </div>
        <div className="counter">
          <h4 className="third">100K+</h4>
          <img src={Counter3Svg} />
          <p>Students worldwide</p>
        </div>
        <div className="counter">
          <h4 className="fourth">1M+</h4>
          <img src={Counter4Svg} />
          <p>Views</p>
        </div>
        <div className="counter">
          <h4 className="fifth">10+</h4>
          <img src={Counter5Svg} />
          <p>Courses</p>
        </div>
      </div>
    </div>
  );
}
